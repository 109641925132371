// membershipSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  membership: null,
  currentPlan: null,
  isSubscribed: false,
  loading: false,
};

const membershipSlice = createSlice({
  name: "membership",
  initialState: initialState,
  reducers: {
    setMembership(state, action) {
      state.membership = action.payload;
    },
    setCurrentPlan(state, action) {
      state.currentPlan = action.payload;
    },
    setIsSubscribed(state, action) {
      state.isSubscribed = action.payload;
      localStorage.setItem("isSubscribed", action.payload);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setMembership, setCurrentPlan, setIsSubscribed, setLoading } =
  membershipSlice.actions;

export default membershipSlice.reducer;
