import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isEdit: false,
  PPID: '',
  productImageLocation: "",
  ProductName: "",
  Description: "",
  StandardPrice: "",
  FinalAmount: "",
  DiscountType: "",
  Discount_Amount: "",
  TypeOfOffer: "",
  ProductAddresses
: [],
};

const OffersSlice = createSlice({
  name: "offers",
  initialState: initialState,
  reducers: {
    updateOffersData(state, action) {
      return { ...state, ...action.payload };
    },
    resetOffersData(state) {
      return initialState;
    },
  },
});

export const { updateOffersData, resetOffersData } = OffersSlice.actions;

export default OffersSlice.reducer;
