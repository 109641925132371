import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  images: {}, // Object to store images, keyed by product PPID
};

const productImagesSlice = createSlice({
  name: 'productImages',
  initialState,
  reducers: {
    setImage: (state, action) => {
      const { PPID, imageUrl  } = action.payload;
      state.images[PPID] = imageUrl ;
    },
  },
});

export const { setImage } = productImagesSlice.actions;

export default productImagesSlice.reducer;
