// import AWS from "aws-sdk";
import Constant from "../config/Constant";
const AWS = require("aws-sdk");
export function getBucketURL(objectKey) {
  AWS.config.update({
    accessKeyId: Constant.AWS_KEY,
    secretAccessKey: Constant.AWS_SECRET_KEY,
    region: Constant.AWS_REGION,
  });

  const s3 = new AWS.S3();

  const params = {
    Bucket: Constant.AWS_BUCKET_NAME,
    Key: objectKey,
  };

  return new Promise((resolve, reject) => {
    s3.getSignedUrl("getObject", params, function (err, url) {
      if (err) {
        reject(err);
      } else {
        resolve(url);
      }
    });
  });
}
